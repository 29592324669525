import React from 'react';
import './App.css';
import Home from './components/Home'
import TempLandingPage from './components/TempLandingPage'



function App() {
	return (
		<div className="App">
			{/* <Home /> */}
			<TempLandingPage />
		</div>
	);
}

export default App;
